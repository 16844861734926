import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import { Auth } from "services/api/Auth";
import apiStore from "./api.store";
import appStore from "./app.store";
import { Request } from "services/api/Request";

export interface ISessionStore  {
    account: {
        accountId: string,
        accountUniqId: string,
        username: string,
        name: string,
        accountEmail: string,
        role: string
    } | undefined,
    apiConnected: boolean,
    valid: boolean,
    sessionId: string,
    token: string,
    refreshToken: string,
    apiToken: string,
    logged: boolean,
    setForgot: boolean,
    setDeletion: string,
    error: any | false,
}

export const initialState: ISessionStore = {
  apiConnected: false,
  account: undefined,
  token: '',
  refreshToken: '',
  apiToken: '',
  sessionId: '',
  valid: false,
  logged: false,
  setForgot: false,
  setDeletion: '',
  error: false,
}

/*
 * ACTIONS
 */

export const sessionStoreAction = {

  loginUser: createAsyncThunk<void, {username: string, password: string}, {}>('session/loginUser', async (payload, thunkApi) => {
    Auth.loginUser(payload.username, payload.password).then((logged:any) => {
      if(logged.hasOwnProperty('id')) {
          thunkApi.dispatch(sessionStore.actions.setLogin(logged));
          thunkApi.dispatch(appStore.actions.setMessage('Anmeldung ist erfolgreich!'));
          return;
      }else{
        thunkApi.dispatch(sessionStore.actions.setLoginFailed(logged));
        thunkApi.dispatch(appStore.actions.setError('Anmeldung ist fehlgeschlagen!'));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setLoginFailed(new ErrorMsg(Errors.API_AUTH__LOGIN_FAILED_BY_EXC, err)));
      thunkApi.dispatch(appStore.actions.setError('Anmeldung ist fehlgeschlagen!'));
      return
    });
  }),


  registerUser: createAsyncThunk<void, {username: string, password: string, email:string}>('session/registerUser', async (payload, thunkApi) => {
    Auth.registerUser(payload.username, payload.password, payload.email).then(logged => {
      if(logged === true) {
          //thunkApi.dispatch(sessionStore.actions.setRegisterd());
          return;
      }else{
        thunkApi.dispatch(sessionStore.actions.setError(logged));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__REGISTER_FAILED_BY_EXC, err)));
      return
    });
  }),

  logoutUser: createAsyncThunk<void>('session/logoutUser', async (payload, thunkApi) => {
    Auth.logout().then(res => {
      if(res === true) {
        thunkApi.dispatch(apiStore.actions.clearStore());
        thunkApi.dispatch(sessionStore.actions.setLogout());
        return;
      }else{
        thunkApi.dispatch(sessionStore.actions.setError(res));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__LOGOUT_FAILED_BY_EXC)));
      return;
    });
  }),  

  checkLogin: createAsyncThunk<void, string>('session/checkLogin', async (payload, thunkApi) => {
    Auth.checkLogin(payload).then((logged:any) => {
      if(logged.hasOwnProperty('id')) {
        thunkApi.dispatch(sessionStore.actions.setLogin(logged));
        return;
      }else{       
         thunkApi.dispatch(sessionStore.actions.setLoginFailed(logged));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setLoginFailed(new ErrorMsg(Errors.API_AUTH__CHECKLOGIN_FAILED_BY_EXC)));
      return;
    });
  }),  

  forgotPassword: createAsyncThunk<void, string>('session/forgotPassword', async (payload, thunkApi) => {
    Auth.forgotPassword(payload).then(forgot => {
      if(forgot === true) {
        //thunkApi.dispatch(sessionStore.actions.setForgot(true));
        return;
      }else{       
         thunkApi.dispatch(sessionStore.actions.setError(forgot));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__REQUEST_FORGOT_FAILED)));
      return;
    });
  }),  

  setForgotPassword: createAsyncThunk<void, {password:string, token:string}>('session/setForgotPassword', async (payload, thunkApi) => {
    Auth.setForgotPassword(payload.password, payload.token).then(forgot => {
      if(forgot === true) {
        thunkApi.dispatch(sessionStore.actions.setForgot(true));
        return;
      }else{       
         thunkApi.dispatch(sessionStore.actions.setError(forgot));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__SET_FORGOT_FAILED)));
      return;
    });
  }),  

  requestDeletion: createAsyncThunk<void, string>('session/requestDeletion', async (payload, thunkApi) => {
    Request.requestDeletion(payload).then(forgot => {
        return;
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC)));
      return;
    });
  }),  

  validateDeletion: createAsyncThunk<void,string>('session/validateDeletion', async (payload, thunkApi) => {
    Request.validateDeletion(payload).then(forgot => {
      console.warn(forgot)
      if(forgot === true) {
        thunkApi.dispatch(sessionStore.actions.setDeletion("success"));
        return;
      }else{       
        thunkApi.dispatch(sessionStore.actions.setDeletion("failed"));
        thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__REQUEST_DELETION_FAILED)));
        return;
      }
    }).catch(err => {
      thunkApi.dispatch(sessionStore.actions.setDeletion("failed"));
      thunkApi.dispatch(sessionStore.actions.setError(new ErrorMsg(Errors.API_AUTH__REQUEST_DELETION_FAILED)));
      return;
    });
  }),  

  initConnection: createAsyncThunk<void>('session/initConnection', async (payload, thunkApi:any) => {
    if(!thunkApi?.getState()?.session?.apiConnected) {
      Api.getAccess().then(res => {
        if(typeof res === 'string') {
          thunkApi.dispatch(sessionStore.actions.setApiConnected(res));
        }else{
          thunkApi.dispatch(sessionStore.actions.setApiFailed(res));
          return;
        }
      }).catch(err => {
        thunkApi.dispatch(sessionStore.actions.setApiFailed(new ErrorMsg(Errors.API__INIT_API_CONNECTION_FAILED_BY_EXC, err)));
        return;
      });
    }
  }),  
};


/*
 * REDUCER
 */

const sessionStore = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorMsg | any>) => {
      state.error = action.payload;
    },
    setClearError: (state, action: PayloadAction) => {
      state.error = false;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setApiToken: (state, action: PayloadAction<string>) => {
      state.apiToken = action.payload;
    },
    setLogin: (state, action: PayloadAction<any>) => {
      state.account = {
        accountId: action.payload?.id,
        accountUniqId: action.payload?.uniqueId,
        username: action.payload?.username,
        name: action.payload?.name,
        accountEmail: action.payload?.email,
        role: action?.payload?.role
      };
      state.token = action.payload?.token;
      state.refreshToken = action.payload?.refreshToken;
      state.logged = true;
      state.error = false;
    },
    updateLogin: (state, action: PayloadAction<any>) => {
      state.account = {
        accountId: action.payload?.id,
        accountUniqId: action.payload?.uniqueId,
        username: action.payload?.username,
        name: action.payload?.name,
        accountEmail: action.payload?.email,
        role: action?.payload?.role
      };
    },
    setLogout: (state, action: PayloadAction) => {
      state.account = undefined
      state.token = '';
      state.logged = false;
      state.error = false;
    },
    setLoginFailed: (state, action: PayloadAction<ErrorMsg | any>) => {
      state.logged = false
      state.error = action.payload
    },
    resetSession: (state, action: PayloadAction) => {
      return initialState;
    },

    setApiConnected: (state, action: PayloadAction<string>) => {
      state.apiConnected = true;
    },
    setForgot: (state, action: PayloadAction<boolean>) => {
      state.setForgot = action.payload;
    },
    setDeletion: (state, action: PayloadAction<string>) => {
      state.setDeletion = action.payload;
    },
    setApiFailed: (state, action: PayloadAction<ErrorMsg | any>) => {
      state.apiConnected = false;
      state.apiToken = '';
      state.error = action.payload
    },
    //sort: state => state.sort((a, b) => a.message.localeCompare(b.message))
  },
  extraReducers: (builder) => {
    /*builder.addCase(updateUser.rejected, (state, action) => {
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    })*/
  },
});

/*
const setState = createAsyncThunk<void,PlayerState,{}>
    ('game/setState', async (state, thunkApi) => {
      Server.game.setState(state);
    
        const { id, ...userData } = user
    const response = await fetch(`https://reqres.in/api/users/${id}`, {
        method: 'PUT',
        headers: {
        Authorization: `Bearer ${thunkApi.extra.jwt}`,
        },
        body: JSON.stringify(userData),
    })
    if (response.status === 400) {
        // Return the known error for future handling
        return thunkApi.rejectWithValue((await response.json()) as any)
    }
    return (await response.json()) as any
})*/

export default sessionStore;
