import React from "react";

import { useSelector } from "react-redux";
import AppMessageView from "@crema/core/AppMessageView";
import AppLoader from "@crema/core/AppLoader";


const AppInfoView = () => {
  const { error, loading, message } = {error: undefined, loading: false, message: undefined}

  const showMessage = () => {
    return <AppMessageView variant="success" message={message.toString()} />;
  };

  const showError = () => {
    return <AppMessageView variant="error" message={error.toString()} />;
  };

  return (
    <>
      {loading && <AppLoader />}

      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default AppInfoView;
