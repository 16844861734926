import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import { Account } from "./types/Auth";
import { SrvErrors } from "./types/Enum";


export class Auth {


    /**
     * Login User By API
     * @param username
     * @param password
     * @return Promise<boolean>
     */
    static loginUser(username: string, password: string):Promise<Account|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/loginUser', 'User: ' + username + ' :: try to login...');

        return new Promise(async (resolve:any, reject:any) => {

            try {
            const response = await Api.post('/auth/login', {
                username: username,
                password: password,
            });

            if(response) {
                    //Parse JSON String "account"
                    let account = await JSON.parse(response.account);
                    account.token = response.token;
                    account.refreshToken = response.refreshToken;

                    Api.setAuthToken(response.token);             
                    Api.setUserData(response);                  


                    LogHelper.logInfo('Services/Api/Auth/loginUser', 'User: ' + username + ' :: login success');
                    resolve(account);
                    return;
            }
            }catch (e) {
                LogHelper.logError('Services/Api/Auth/loginUser', 'User: ' + username + ' :: login failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_AUTH__LOGIN_FAILED_BY_EXC, e);
                return reject(Api.error);
            }

            //Login failed
            LogHelper.logInfo('Services/Api/Auth/loginUser', 'User: ' + username + ' :: login failed');
            Api.error = new ErrorMsg(Errors.API_AUTH__LOGIN_FAILED);
            reject(Api.error);
            return;
        });

    }

    /**
     * Register User By API
     * @param username
     * @param password
     * @param email
     * @return Promise<boolean>
     */
    static registerUser(username: string, password: string, email:string):Promise<true|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/register', 'User: ' + username + ' :: try to register...');

        return new Promise(async (resolve:any, reject:any) => {

            try {
            const response = await Api.post('/auth/register', {
                username: username,
                password: password,
                email: email
            });

            if(response) {

                if(response.error && response.error == SrvErrors.ACCOUNT_ALREADY_EXIST) {
                    LogHelper.logError('Services/Api/Auth/registerUser', 'Register User: ' + username + ' :: failed account already exist');
                    Api.error = new ErrorMsg(Errors.API_AUTH__REGISTER_FAILED_ALREADY_USED_USER_OR_EMAIL);
                    return reject(Api.error);
                }   
                    //Parse JSON String "account"
                    let account = await JSON.parse(response.account);
                    
                    if(account.id) {
                        return resolve(true);
                    }
                    
            }
            }catch (e) {
                LogHelper.logError('Services/Api/Auth/registerUser', 'Register User: ' + username + ' :: failed by parse JSON exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_AUTH__REGISTER_FAILED_BY_EXC, e);
                return reject(Api.error);
            }

            //Login failed
            LogHelper.logInfo('Services/Api/Auth/registerUser', 'User: ' + username + ' :: register failed');
            Api.error = new ErrorMsg(Errors.API_AUTH__REGISTER_FAILED);
            return reject(Api.error);
            
        });
    }



    /**
     * CheckLogin By API
     * @return Promise<boolean>
     * @param token
     */
    static checkLogin(token: string):Promise<Account|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/checkLogin', 'try to checkLogin...');

        return new Promise(async (resolve:any, reject:any) => {

            try {
            const response = await Api.post('/auth/check', {
                refreshToken: token
            });

            if(response) {
                    let account = await JSON.parse(response.account);
                    account.token = response?.token;
                    account.refreshToken = response?.refreshToken;
                

                    Api.setAuthToken(response.token);                  
                    Api.setUserData(response);                  

                    LogHelper.logInfo('Services/Api/Auth/checkLogin', 'User: ' + account.username + ' :: checkLogin success');
                    resolve(account);
                    return;
            }
            }catch (e) {
                LogHelper.logError('Services/Api/Auth/checkLogin', 'checkLogin failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API_AUTH__CHECKLOGIN_FAILED_BY_EXC, e);
                return reject(Api.error);
            
            }

            LogHelper.logInfo('Services/Api/Auth/checkLogin', 'checkLogin failed');
            Api.error = new ErrorMsg(Errors.API_AUTH__CHECKLOGIN_FAILED);
            return reject(Api.error);
        });

    }


    /**
     * CheckLogin By API
     * @return Promise<boolean>
     * @param token
     */
    static forgotPassword(email: string):Promise<true|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/forgotPassword', 'try to reset Password...');

        return new Promise(async (resolve:any, reject:any) => {

            try {
                const response = await Api.post('/auth/forgot', {
                    email: email,
                });
    
                if(response && (response.message === 'success' || response.error === 'failed')) {
                    resolve(true);
                    return
                }
            }catch(e) {
                LogHelper.logInfo('Services/Api/Auth/forgotPassword', 'forgotPassword failed');
                Api.error = new ErrorMsg(Errors.API_AUTH__REQUEST_FORGOT_FAILED);
                return reject(Api.error);
            }
            
            LogHelper.logInfo('Services/Api/Auth/forgotPassword', 'forgotPassword failed');
            Api.error = new ErrorMsg(Errors.API_AUTH__REQUEST_FORGOT_FAILED);
            return reject(Api.error);
        });

    }   
    
    
    /**
     * Set Forgot By API
     * @return Promise<boolean>
     * @param token
     */
    static setForgotPassword(password: string, token:string):Promise<true|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/setForgotPassword', 'try to set new Password...');

        return new Promise(async (resolve:any, reject:any) => {

            try {

                const response = await Api.post('/auth/setforgot', {
                    password: password,
                    token: token
                });
    
                if(response && response.message === 'success') {
                    resolve(true);
                    return
                }
            }catch(e) {
                LogHelper.logInfo('Services/Api/Auth/setForgotPassword', 'setForgotPassword failed');
                Api.error = new ErrorMsg(Errors.API_AUTH__SET_FORGOT_FAILED);
                return reject(Api.error);
            }
        });

    }

    /**
     * Logout User clear Storage
     * @return Promise<boolean>
     */
    static logout():Promise<true|ErrorMsg> {

        LogHelper.logInfo('Services/Api/Auth/logout', 'logout user...');

        return new Promise(async (resolve:any, reject:any) => {
            LogHelper.logInfo('Services/Api/Auth/logout', 'logout user success');
            resolve(true);

            /*
            .catch(err => {
                LogHelper.logError('Services/Api/Auth/logout', 'logout user failed: ' + err.message);
                Api.error = new ErrorMsg(Errors.API_AUTH__LOGOUT_FAILED_BY_EXC, err);
                reject(Api.error);
            })*/
        });
    }
}
