import { ReactNode } from "react";
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  /*
  {
    id: "customer",
    title: "Customer",
    messageId: "sidebar.customer",
    permittedRole: RoutePermittedRole.User,
    type: "group",
    children: [
      {
        id: "customerdashboard",
        title: "Dashboard",
        messageId: "sidebar.customer.dashboard",
        type: "item",
        icon: <Dashboard fontSize="inherit" />,
        url: "/dashboard",
      },
  ]},*/
];
export default routesConfig;
