
import { Api } from "App";
import Config from "config/Config";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";
import SecureHelper from "helpers/SecureHelper";
import { delay } from "helpers/Tools";
import sessionStore from "store/session.store";
import { store } from "store/store";
import { Account } from "./Account";

export default class ApiClient {

    env: string = 'PRODUCTION';
    apiUrl!: string;
    apiKey1!: string;
    apiKey2!: string;
    token!: string;
    apiToken!: string;
    uniqueId!: string;
    metricToken!:string;
    userData!: any;

    refreshing: boolean = false;
    error: ErrorMsg|any = false;
    errorClient: ErrorMsg|any = false;

    constructor() {
       this.apiUrl = Config.api.apiUrl;
       this.apiKey1 = Config.api.apiKey1;
       this.apiKey2 = Config.api.apiKey2;
       this.uniqueId = 'unknown';
    }

    changeEnv(env:any|'DEV'|'STAGING'|'PRODUCTION') {
        this.env = env;
    }

    /**
     * Setzt den AuthToken für ApiRequests
     * @param token
     */
    setAuthToken(token:string) {
        this.token = token;
    }

    /**
     * Setzt den AuthToken für ApiRequests
     * @param token
     */
    setApiToken(token:string) {
        this.apiToken = token;
    }


    /**
     * setUserData
     * @param userdata
     */
    setUserData(userdata:string) {
        this.userData = userdata;
    }


    /**
     * GetAccess - Get ApiToken from Server
     * @param uniqueId
     */
    async getAccess(uniqueId:string|null = null):Promise<string | ErrorMsg> {

        try{
            if(uniqueId == null) {
                uniqueId = this.uniqueId;
            }

            LogHelper.logInfo('Services/Api/ApiClient/getaccess', 'Getting Access to API....');
            const response = await this.post('/core/getaccess',
                {
                    apiKey: SecureHelper.generateApiKey(this.apiKey1, this.apiKey2),
                    uniqueId: uniqueId,
                });


            if(response && response.apiToken) {
                LogHelper.logInfo('Services/Api/ApiClient/getaccess', 'Getting Access to API success');
                this.setApiToken(response.apiToken);
                return this.apiToken;
            }else{
                LogHelper.logError('Services/Api/ApiClient/getaccess', 'Getting Access to API failed: response or apiToken not available: ' + JSON.stringify(response));
                this.error = new ErrorMsg(Errors.API_GETACCESS__FAILED_NO_TOKEN);
                return new ErrorMsg(Errors.API_GETACCESS__FAILED_NO_TOKEN);
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/getaccess', 'Getting Access to API failed hard: ', e);
            this.error = new ErrorMsg(Errors.API_GETACCESS__FAILED_BY_EXC, e);
            return new ErrorMsg(Errors.API_GETACCESS__FAILED_BY_EXC, e);
        }

    }


    /**
     * GetAccess - Get ApiToken from Server
     * @param uniqueId
     */
     async refreshToken():Promise<Account | false | 'skipped'> {

        try{
            if(this.refreshing) {
                LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'Already refreshing token... wait 3 seconds');
                await delay(3000);

                if(this.userData) {
                    return this.userData
                }
                return 'skipped'
            }
            this.refreshing = true;
            LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'Refresh Token on API....');

            if(!this.userData) {
                this.refreshing = false;
                this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED_NO_STOREAGE_DATA);
                return false;
            }

            const response = await this.post('/auth/refresh',
                {
                    refreshToken: this.userData.refreshToken,
                }, false);


            if(response && response.token) {
                LogHelper.logInfo('Services/Api/ApiClient/refreshToken', 'refresh token success');
                this.setAuthToken(response.token);

                this.userData = response;

                this.refreshing = false;
                return this.userData
            }else{
                LogHelper.logError('Services/Api/ApiClient/refreshToken', 'refresh token failed: response or token not available: ' + JSON.stringify(response));
                this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED);
                store.dispatch(sessionStore.actions.setLogout());
                this.refreshing = false;
                return false
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/refreshToken', 'refresh token failed hard: ', e);
            this.error = new ErrorMsg(Errors.API_REFRESHTOKEN__FAILED_BY_EXC, e);
            this.refreshing = false;
            return false
        }

    }

    /**
     * Get Request
     * @param url
     * @param body
     * @param headers
     */
    async get(url:string, body:any, saveApi: boolean = false, headers:any = [], only200?: boolean) {

        let refreshed = false;
        const host = this.env == 'DEV' ? this.apiUrl :  this.env == 'STAGING' ? this.apiUrl : this.apiUrl;
        try{
            let timeout;
            const runFetch = async (token?:any) => new Promise<false|Response>((resolve, reject) =>
            {
                fetch(host + url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': saveApi ? (token ?? this.token) : this.apiToken,
                        'Access-UniqueId': !saveApi ? this.uniqueId : '',
                        ...headers,
                    },
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'GET-Request failed: ' + err );
                    Api.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, err);
                    reject(false);
                });
                timeout = setTimeout(reject.bind(null, Errors.API__TIMEOUT_ERROR), Config.api.timeout);
            });


            let response = await runFetch();
            if(timeout) clearTimeout(timeout)

            if(response) {
                if(response && only200 && response.status == 200) {
                    return true;
                }

                if(response && response.status == 401) {
                    const resp = await response.json();
                    if(resp.error && (resp.error == 'token_expired' || resp.error == 'token_invalid') && !refreshed) {
                        LogHelper.logInfo('Services/Api/ApiClient/get', 'GET-Request token expired, refreshing');
                        refreshed = true;

                        const acc:any = await this.refreshToken();
                        if(acc && acc['token']) {
                            response = await runFetch(this.token);
                        }
                    }
                }

                if(response && response.status != 200) {
                    LogHelper.logInfo('Services/Api/ApiClient/get', 'GET-Request not 200 : ' + url + ' - ' + response.status);
                }

                if(response && !response.bodyUsed) {
                    return await response.json();
                }else if(response) {
                    return response.body
                }

                this.error = new ErrorMsg(Errors.API_GET__FAILED_BY_EXC, response);
                throw new Error(this.error);
            }
        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/get', 'GET-Request failed: ', e);
            this.error = new ErrorMsg(Errors.API_GET__FAILED_BY_EXC, e);
            throw new Error(this.error);
        }

    }


    /**
     * POST Request
     * @param url
     * @param body
     * @param saveApi
     * @param headers
     */
    async post(url:string, body:any, saveApi: boolean = false, headers:any = []) {

        let refreshed = false;

        const host = this.env == 'DEV' ? this.apiUrl :  this.env == 'STAGING' ? this.apiUrl : this.apiUrl;
        try{
            let timeout
            const runFetch = async (token?:any) => new Promise<false|Response>((resolve, reject) =>
            {
                fetch(host + url, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': saveApi ? (token ?? this.token) : this.apiToken,
                        'Access-UniqueId': !saveApi ? this.uniqueId : '',
                        ...headers,
                    },
                    body: JSON.stringify(body),
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-Request failed: ' + err );
                    Api.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, err);
                    reject(err);
                });
                timeout = setTimeout(reject.bind(null, Errors.API__TIMEOUT_ERROR), Config.api.timeout);
            });

            let response = await runFetch();
            if(timeout) clearTimeout(timeout)

            //If Status Response HTTP 200 OK
            if (response) {
                let resp;
                if(response.status == 401) {
                    resp = await response.json();
                    if(resp.error && (resp.error == 'token_expired' || resp.error == 'token_invalid') && !refreshed) {
                        refreshed = true;
                        LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-Request token expired, refreshing');

                        const acc:any = await this.refreshToken();
                        if(acc && acc['token']) {
                            response = await runFetch(this.token);
                        }
                    }
                }

                if(response && response.status != 200) {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-Request not 200: ' + url + ' - ' + response.status);
                }

                if(response && !response.bodyUsed) {
                    return await response.json();
                }else if(response) {
                    return response.body
                }
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-Response no response');

                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error);
            } else {
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-Response failed: ' + Api.error.error + ' ');
                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error);
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/post', 'POST-Request failed by exception: ', e);
            this.error = new ErrorMsg(Errors.API_POST__FAILED_BY_EXC, e);
            throw new Error(this.error);
        }
    }


    /**
     * Get Request
     * @param url
     * @param body
     * @param headers
     */
     async delete(url:string, body:any, saveApi: boolean = false, headers:any = []) {

        const host = this.env == 'DEV' ? this.apiUrl :  this.env == 'STAGING' ? this.apiUrl : this.apiUrl;
        try{
            const response = await fetch(host + url, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': saveApi ? this.token : this.apiToken,
                    'Access-UniqueId': saveApi ? this.uniqueId : '',
                    ...headers,
                },
                body: JSON.stringify(body),
            });
            return await response.json();
        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/get', 'DELETE-Request failed: ', e);
            this.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, e);
            throw new Error(this.error);
        }

    }





    /**
     * POST Request
     * @param url
     * @param body
     * @param saveApi
     * @param headers
     */
     async postFile(url:string, file:any, fileType:string = "text/csv") {

        let refreshed = false;
        let saveApi = true

        try{

            let formData:any;

            formData = new FormData();
            const blob = new Blob([file], {type: fileType});
            formData.append("file", blob);


            let timeout;

            const runFetch = async (token?:any) => new Promise<false|any>((resolve, reject) =>
            {

                try {
                    const xhr = new XMLHttpRequest();
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve(JSON.parse(xhr.response));
                        } else {
                            LogHelper.logWarn('Services/Api/ApiClient/post', 'POST-XHR-Request not 200: ' + url + ' - ' + xhr.status);
                            resolve(JSON.parse(xhr.response))
                        }
                    };

                    xhr.open("POST", this.apiUrl + url);
                    //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                    xhr.setRequestHeader('Accept', 'application/json');
                    xhr.setRequestHeader('Authorization', saveApi ? (token ?? this.token) : this.apiToken);
                    xhr.setRequestHeader('Access-UniqueId', !saveApi ? this.uniqueId : '',);
                    xhr.send(formData);
                    timeout = setTimeout(reject.bind(null, Errors.API__TIMEOUT_ERROR), Config.api.timeout);
                   

                }catch(e:any) {
                    LogHelper.logInfo('Services/Api/ApiClient/post', 'POST-XHR-Request failed: ' + e );
                    Api.error = new ErrorMsg(Errors.API__REQUEST_FAILED_BY_EXC, e);
                    reject(e);
                }
            });

            let response:any = await runFetch();
            if(timeout) clearTimeout(timeout)

            //If Status Response HTTP 200 OK
            if (response) {
                if(response?.error) {
                    if((response.error == 'token_expired' || response.error == 'token_invalid') && !refreshed) {
                        refreshed = true;
                        LogHelper.logWarn('Services/Api/ApiClient/post', 'POST-XHR-Request token expired, refreshing');

                        const acc = await this.refreshToken() as Account;
                        if(acc && acc['token']) {
                            response = await runFetch(this.token);
                        }
                    }
                }

                if(response && !response.error) {
                    return response
                }
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-XHR-Response no response');

                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error);
            } else {
                LogHelper.logError('Services/Api/ApiClient/post', 'POST-XHR-Response failed: ' + Api.error.error + ' ');
                this.error = new ErrorMsg(Errors.API_POST__FAILED, response);
                throw new Error(this.error?.error?.message);
            }

        }catch (e) {
            LogHelper.logError('Services/Api/ApiClient/post', 'POST-XHR-Request failed by exception: ', e);
            this.error = new ErrorMsg(Errors.API_POST__FAILED_BY_EXC, e);
            throw e
        }
    }

}
