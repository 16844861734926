import React from "react";


const AccountDashboard = React.lazy(() => import("./AccountDashboard"));
const AccountDelete = React.lazy(() => import("./AccountDelete"));

export const accountConfigs = [
  {
    auth: ['user','admin'],
    path: "/account/dashboard",
    element: <AccountDashboard />,
  },
  {
    auth: ['user','admin'],
    path: "/account/delete",
    element: <AccountDelete />,
  }
];
