export const Black = '#000000';
export const White = '#ffffff';

export const Dark1 = '#262a2e';
export const Dark2 = '#373f46';
export const Dark3 = '#3a4248';

export const Greye = '#eeeeee';
export const Greyd = '#dddddd';
export const Greyc = '#cccccc';
export const Greyb = '#bbbbbb';
export const Greya = '#aaaaaa';
export const Grey9 = '#999999';
export const Grey8 = '#888888';
export const Grey7 = '#777777';
export const Grey6 = '#666666';
export const Grey5 = '#555555';
export const Grey4 = '#444444';
export const Grey40 = '#404040';
export const Grey3 = '#333333';
export const Grey2 = '#222222';
export const Grey1 = '#111111';

export const Green = '#2c8800';
export const DarkGreen = '#174500';
export const LightGreen = '#679e00';
export const LighterGreen = 'rgb(181,210,96)';
export const DarkLightGreen = '#3e5e00';
export const MildRed = '#dc3545';  
export const LightRed = '#ff7878';
export const Red = '#ab000a';
export const DarkRed = '#570008';
export const Yellow = '#ecbc00';
export const DarkYellow = '#876f14';
export const Orange = '#ec6c00';
export const LightOrange = '#ffa746';
export const Purple = '#6f42c1';
export const LightBlue = '#4599d0';
export const Blue = '#0079d0';
export const DarkBlue = '#003156';
export const LightBrown = '#ac9373';
export const Brown = '#9d6e3b';
export const MiddleBrown = 'rgba(88, 53, 9, 1)';
export const DarkBrown = '#473020';
export const DarkerBrown = '#271d10';
export const DarkstBrown = '#140f06';
export const Magenta = '#e20074'

export const Error = Red;
export const Success = LightGreen;
export const Warning = '#ec6c00';
export const Info = Blue;
export const Suspect = '#6f42c1';
export const Undefined = '#grey5;';

export const InfoLight = '#d4f1ff';
export const SuccessLight = '#deefd5';
export const DangerLight = '#ffeeee';
export const WarningLight = '#fff0d4';
export const MagentaLight = '#ffbee0';

export const Error_trans7 = 'rgba(171,0,10,0.71)';
export const Success_trans7 = 'rgba(103,158,0,0.71)';
export const Warning_trans7 = 'rgba(236,108,0,0.71)';
export const Info_trans7 = 'rgba(0,121,208,0.71)';

export const White_trans05 = 'rgba(255,255,255,0.05)';
export const White_trans1 = 'rgba(255,255,255,0.1)';
export const White_trans2 = 'rgba(255,255,255,0.2)';
export const White_trans3 = 'rgba(255,255,255,0.3)';
export const White_trans5 = 'rgba(255,255,255,0.5)';
export const White_trans7 = 'rgba(255,255,255,0.7)';
export const White_trans8 = 'rgba(255,255,255,0.8)';
export const White_trans9 = 'rgba(255,255,255,0.9)';

export const Magenta_trans3 = 'rgba(226, 0, 116, 0.3)';
export const Magenta_trans5 = 'rgba(226, 0, 116, 0.5)';
export const Magenta_trans7 = 'rgba(226, 0, 116, 0.7)';
export const Magenta_trans9 = 'rgba(226, 0, 116, 0.9)';

export const LighterGreen_trans7 = 'rgba(163,210,104,0.71)';

export const LightGreen_trans1 = 'rgba(103,158,0,0.1)';
export const LightGreen_trans2 = 'rgba(103,158,0,0.2)';
export const LightGreen_trans3 = 'rgba(103,158,0,0.3)';
export const LightGreen_trans5 = 'rgba(103,158,0,0.5)';
export const LightGreen_trans7 = 'rgba(103,158,0,0.71)';

export const Orange_trans1 = 'rgba(236, 108, 0, 0.1)';
export const Orange_trans2 = 'rgba(236, 108, 0, 0.2)';
export const Orange_trans3 = 'rgba(236, 108, 0, 0.3)';
export const Orange_trans5 = 'rgba(236, 108, 0, 0.5)';
export const Orange_trans7 = 'rgba(236, 108, 0, 0.7)';

export const Red_trans05 = 'rgba(171, 0, 10, 0.05)';
export const Red_trans1 = 'rgba(171, 0, 10, 0.1)';
export const Red_trans2 = 'rgba(171, 0, 10, 0.2)';
export const Red_trans3 = 'rgba(171, 0, 10, 0.3)';
export const Red_trans5 = 'rgba(171, 0, 10, 0.5)';
export const Red_trans7 = 'rgba(171, 0, 10, 0.7)';

export const MildRed_trans5 = 'rgba(220, 53, 69, 0.5)';
export const MildRed_trans7 = 'rgba(220, 53, 69, 0.7)';

export const Yellow_trans05 = 'rgba(236, 188, 0, 0.05)';
export const Yellow_trans1 = 'rgba(236, 188, 0, 0.1)';
export const Yellow_trans2 = 'rgba(236, 188, 0, 0.2)';
export const Yellow_trans3 = 'rgba(236, 188, 0, 0.3)';
export const Yellow_trans5 = 'rgba(236, 188, 0, 0.5)';
export const Yellow_trans7 = 'rgba(236, 188, 0, 0.7)';

export const Green_trans5 = 'rgba(44, 136, 0, 0.5)';
export const Green_trans7 = 'rgba(44, 136, 0, 0.7)';

export const Blue_trans1 = 'rgba(0, 121, 208, 0.1)';
export const Blue_trans2 = 'rgba(0, 121, 208, 0.2)';
export const Blue_trans3 = 'rgba(0, 121, 208, 0.3)';
export const Blue_trans5 = 'rgba(0, 121, 208, 0.5)';
export const Blue_trans7 = 'rgba(0, 121, 208, 0.7)';


export const Greye_trans1 = 'rgba(238, 238, 238, 0.1)';
export const Greye_trans3 = 'rgba(238, 238, 238, 0.3)';
export const Greye_trans5 = 'rgba(238, 238, 238, 0.5)';

export const LightBrown_trans1 = 'rgba(172,147,115,0.1)';
export const LightBrown_trans2 = 'rgba(172,147,115,0.2)';
export const LightBrown_trans3 = 'rgba(172,147,115,0.3)';
export const LightBrown_trans5 = 'rgba(172,147,115,0.5)';
export const LightBrown_trans7 = 'rgba(172,147,115,0.7)';
export const LightBrown_trans8 = 'rgba(172,147,115,0.8)';
export const LightBrown_trans9 = 'rgba(172,147,115,0.9)';


export const MiddleBrown_trans3 = 'rgba(34, 19, 2, 0.3)';
export const MiddleBrown_trans5 = 'rgba(34, 19, 2, 0.5)';
export const MiddleBrown_trans7 = 'rgba(34, 19, 2, 0.7)';
export const MiddleBrown_trans8 = 'rgba(34, 19, 2, 0.8)';
export const MiddleBrown_trans9 = 'rgba(34, 19, 2, 0.9)';

export const Brown_trans7 = 'rgba(157,110,59,0.71)';
export const Brown_trans5 = 'rgba(157,110,59,0.5)';

export const DarkBrown_trans1 = 'rgba(71, 48, 32, 0.1)';
export const DarkBrown_trans2 = 'rgba(71, 48, 32, 0.2)';
export const DarkBrown_trans3 = 'rgba(71, 48, 32, 0.3)';
export const DarkBrown_trans5 = 'rgba(71, 48, 32, 0.5)';
export const DarkBrown_trans7 = 'rgba(71, 48, 32, 0.7)';
export const DarkBrown_trans9 = 'rgba(71, 48, 32, 0.9)';
export const DarkestBrown_trans7 = 'rgba(67,45,30,0.7)';
export const DarkesterBrown_trans7 = 'rgba(36,21,18,0.7)';
export const DarkesterBrown_trans9 = 'rgba(36,21,18,0.9)';
export const DarkesterBrown = 'rgba(36,21,18,1.0)';
export const DarkestsBrown_trans5 = 'rgba(21,10,10,0.5)';
export const DarkestsBrown_trans7 = 'rgba(21,10,10,0.7)';
export const DarkestsBrown_trans8 = 'rgba(21,10,10,0.8)';
export const DarkestsBrown_trans9 = 'rgba(21,10,10,0.9)';
export const DarkestsBrown = 'rgba(21,10,10,1.0)';
export const DarkerDarkBrown = 'rgba(51, 26, 0, 1.0)';
export const DarkerDarkBrown_trans8 = 'rgba(18, 8, 8, 0.8)';
export const DarkerDarkBrown_trans9 = 'rgba(18, 8, 8, 0.9)';

export const Black_trans3 = 'rgba(0,0,0,0.3)';
export const Black_trans5 = 'rgba(0,0,0,0.5)';
export const Black_trans7 = 'rgba(0,0,0,0.7)';

export const colorArray = [LightGreen, Blue, Yellow, Red, Orange, Purple]