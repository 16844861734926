import { deDE } from "@mui/material/locale";
import deMessages from "../locales/de_DE.json";

const DeLang = {
  messages: {
    ...deMessages,
  },
  muiLocale: deDE,
  locale: "de-DE",
};
export default DeLang;
