import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import jwtAxios, { setAuthToken } from "./index";
import { AuthUser } from "../../../../types/models/AuthUser";
import { Api } from "App";
import { sessionStoreAction } from "store/session.store";
import { RootState } from "store/store";
import { authRole } from "shared/constants/AppConst";

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const storeSessionAccount = useSelector((state: RootState) => state.session.account);
  const storeSessionRefreshToken = useSelector((state: RootState) => state.session.refreshToken);
  const storeSessionError = useSelector((state: RootState) => state.session.error);

  const dispatch = useDispatch();

  const parseAuthDataFromSession = () => {
    if(storeSessionAccount) {
      const user:AuthUser = {
        id: parseInt(storeSessionAccount?.accountId),
        uid: storeSessionAccount?.accountUniqId,
        email: storeSessionAccount?.accountEmail,
        role:  storeSessionAccount?.role == 'admin' ? authRole.admin : authRole.user,
        displayName: storeSessionAccount?.name,
        username: storeSessionAccount?.username,
      }
      return user;
    }
    return undefined
  }

  useEffect(() => {
    

    const getAuthUser = () => {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      dispatch(sessionStoreAction.checkLogin(refreshToken));
    };

    getAuthUser();
  }, []);

  useEffect(() => {
    const sessionUser =  parseAuthDataFromSession();
    if(sessionUser) {
        localStorage.setItem("refreshToken", storeSessionRefreshToken);
      setJWTAuthData({
        user: {...sessionUser, token: storeSessionRefreshToken},
        isLoading: false,
        isAuthenticated: sessionUser ? true : false,
      });
    }
  }, [storeSessionAccount]);

  
  useEffect(() => {
    if(storeSessionError) {
      const sessionUser =  parseAuthDataFromSession();

      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: sessionUser ? true : false,
        isLoading: false,
      });
    }
  }, [storeSessionError]);

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      dispatch(sessionStoreAction.loginUser({username: email, password: password}));
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(() => undefined);
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    dispatch(sessionStoreAction.logoutUser());
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
