import { format } from "date-fns";

export function randomNumber(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomNumberDirect() { // min and max included
    return Math.floor(Math.random() * (9999999 - 1000000 + 1) + 1000000);
}

//Form
export function onlyNumbers(func:() => void, value:any) {
    if(/^\d+$/.test(value) || value === '') {
        return func();
    }
    return undefined;
}

//Tools
export function formattedTimeStamp():string{
    return new Date().toISOString().
    replace(/T/, ' ').      // replace T with a space
        replace(/\..+/, '')
}

export function parseTimeToFormated(date:string):string{
    if(!date) {
        return 'no date';
    }

    const time = Date.parse(date);
    return new Date(time).toISOString().
    replace(/T/, ' ').      // replace T with a space
        replace(/\..+/, '')
}

export function formatDateTime(date:string):string{
    if(!date) {
        return 'no date';
    }

    const time = new Date(date);
    return format(time, 'dd.MM.yyyy HH:mm:ss')
}

export function objArrayCheckIfAllParmEqual(object:object, field:any, para:any, ignore = false):boolean|number{

    let count = 0;
    for(let obj of Object.values(object)){
        if(ignore &&  obj[field] === ignore) continue;
        if(obj[field] === para){
            count++;
        }
    }

    return count;
}

export async function delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms))
    //.then(()=>console.log("fired"));
}


export function truncateString(string:string, maxlength:number){
    return string.substring(0,maxlength-1)+(string.length>maxlength?'...':'');
}

export function checkStringHasSpecialOrSpaceChars(string:string) {
    return string.match(/[^a-zA-Z0-9-_]/) ? true : false
}

export function checkStringHasSpecialChars(string:string) {
    return string.match(/[^a-zA-Z0-9-_ ]/) ? true : false
}

export function checkStringIsEmail(string:string) {
    return string.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)
}

export function sortObjectMinToHeight(obj:any, key:string) {

    let entries = Object.entries(obj);
    let sorted = entries.sort((a:any, b:any) =>  a[1][key] - b[1][key]);
    
    return sorted;
}
export function sortObjectHeightToMinByKey(obj:any, key:any) {

    let entries = Object.entries(obj);
    let sorted = entries.sort((a:any, b:any) =>  b[1][key] - a[1][key]);
    
    return sorted;
}
export function sortObjectHeightToMin(obj:any) {

    let entries = Object.entries(obj);
    let sorted = entries.sort((a:any, b:any) =>  b[1] - a[1]);
    
    return sorted;
}

export function sumDuplicates(array:any, key:string, prop:string) {

    const list:any = {};

    array.map((e:any) => {
        if(list[e[key]]) {
            list[e[key]] = list[e[key]] + parseInt(e[prop]);
        }else{
            list[e[key]] = parseInt(e[prop]);
        }
    });

    return list;
}

export function getRanking(obj:any) {

    let entries:any = {};
    let rank = 1;
    let points = 0;

    const data = sortObjectMinToHeight(obj, 'completeScore');
    

    data.forEach((d:any) => {
        if(points == d[1]?.completeScore && rank > 1) {
            rank = rank-1;
        }
        entries[d[0]] = rank;

        rank++;
        points = d[1]?.completeScore
    });
    
    return entries;
}

export function arrayCountDuplicates(array: any[]){
    let counts: any = {};
    for (let i = 0; i < array.length; i++) {
        counts[array[i]] = 1 + (counts[array[i]] || 0);
    }

    return counts;
}

export function arrayCountDuplicatesOnProperty(array: any[], prop: string){
    let counts: any = {};
    for (let i = 0; i < array.length; i++) {
        counts[array[i][prop]] = [array[i], 1 + (counts[array[i][prop]] ? counts[array[i][prop]][1] : 0 || 0)];
    }

    return Object.values(counts);
}/**
 * Return a String with "P" Prefix for PlayerKeys on MapSchema
 * @param string
 */
export const preP = (string:string|undefined) => {
    if(!string) {
        return '';
    }
    return 'P' + string;
}

/**
 * Remove the P-Prefix from String
 * @param string
 */
export const reP = (string:string) => {
    if(!string) {
        return '';
    }
    return string.replace('P', '');
}



export const formatDuration = (duration:number|any, noHours?:boolean):any => {
        
    if(!duration) {
        return '00:00';
    }
    if(noHours) {
        return new Date((duration ? duration : 0) * 1000).toISOString().substr(11, 8).substr(3,9);
    }
    return new Date((duration ? duration : 0) * 1000).toISOString().substr(11, 8);
};



export function sumArrayOfNumbers(arr:number[]) {
    return arr.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
}

export function toggleFromArray(arr:any[], item:any) {
    return arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];

}