
import { Api } from "App";
import { Errors } from "data/const/Errors";
import { ErrorMsg } from "data/models/ErrorMsg";
import LogHelper from "helpers/LogHelper";

export class Request {


    /**
     * Company of User
     * @param companyId
     * @return Promise<boolean>
     */
    static newRequest(data:{accountid:any,type:string, subtype:string, text:string}): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/request/newRequest', data, true);

                if (response && response.request) {
                        //Parse JSON String "company"
                        LogHelper.logInfo('Services/Api/Request/newRequest', 'create request success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Request/newRequest', 'create request failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Request/newRequest', 'create request failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Request Accoutn Deletion
     * @param companyId
     * @return Promise<boolean>
     */
    static requestDeletion(data:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/request/deletionRequest', {
                email: data
            }, true);

                if (response && response.request) {
                        //Parse JSON String "company"
                        LogHelper.logInfo('Services/Api/Request/deletionRequest', 'create deletionRequest success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Request/deletionRequest', 'create deletionRequest failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Request/deletionRequest', 'create deletionRequest failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    /**
     * Validate Request Accoutn Deletion
     * @param companyId
     * @return Promise<boolean>
     */
    static validateDeletion(data:string): Promise<true | false> {

        return new Promise(async (resolve: any, reject:any) => {

            try {
            const response = await Api.post('/request/confirmDeletionRequest', {
                token: data
            }, true);

                if (response && response.message) {
                        //Parse JSON String "company"
                        LogHelper.logInfo('Services/Api/Request/confirmDeletionRequest', 'create confirmDeletionRequest success');
                        resolve(true);
                        return;
                }

                //Login failed
                LogHelper.logError('Services/Api/Request/confirmDeletionRequest', 'create confirmDeletionRequest failed', response);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED);
                reject(response ?? Api.error);
                return;
            } catch (e) {
                LogHelper.logError('Services/Api/Request/confirmDeletionRequest', 'create confirmDeletionRequest failed by exception: ' + e);
                Api.error = new ErrorMsg(Errors.API__LOAD_FAILED, e);
                reject(Api.error);
                return;
            }
        });

    }

    
}
