import React from "react";
import { Navigate } from "react-router-dom";


const GetApp = React.lazy(() => import("./GetApp"));

export const publicConfigs = [
  {
    path: "/getapp",
    element: <GetApp />,
  }
];
