import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import AppWrapperHeader from "./AppWrapperHeader";

interface WrapperProps {
  children: any;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: 900,
          backgroundColor: '#473020',
          minHeight: { xs: 320, sm: 450 },
          width: "95%",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginBottom: 20
        }}
      >
         <Box
          sx={{
            width: { xs: "100%" },
            padding: { xs: 5 },
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <AppWrapperHeader />
        </Box>
        <Box
          flexGrow={1}
          sx={{
            width: { xs: "100%" },
            backgroundColor: '#ffffff',
            padding: { xs: 5, lg: 10 },
            flexDirection: "column",
            justifyContent: "center",
            display: 'flex',
          }}
        >
          {children}
        </Box>
      </Card>
    </Box>
  );
};

export default Wrapper;
