import { Boy } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import "./loader.css";

const AppLoader = () => {

  return (<Box display="flex" alignItems={'center'} justifyContent={'center'} m={10}>
    <CircularProgress color='error' />
    </Box>);
  return (
    <div className="app-loader">
      <div className="loader-spin">
        <span className="crema-dot crema-dot-spin">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

export default AppLoader;
