import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import LogHelper from "helpers/LogHelper";
import { CACHETIME } from "services/api/types/Enum";
import { RootState } from "./store";
import { isCacheExpired } from "./StoreHelper";

export interface IApiCacheItem<T = any>  {
    state: number,
    time: number,
    payload: any,
    data: T
  }
export interface IApiStore  {
    cache: any
  }

export const initialState: IApiStore = {
  cache: {
    profile_detail: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_complete: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_games: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    profile_lastexps: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playersmeta: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    game: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    gamerounds: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    round: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playerround: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
    playerexp: {
      state: 0,
      time: 0,
      payload: undefined,
      data: undefined
    },
  },
}

/*
 * ACTIONS
 */


export const apiStoreAction = {
    /* profile */
   /*
    playerexp: createAsyncThunk<void, {playerId:string, type:string, actionId:number}, {state: RootState}>('api/playerexp', async (payload, thunkApi) => {
      try {
        const current = thunkApi.getState().api.cache.playerexp;
        if(current?.payload == payload && !isCacheExpired(current?.time, CACHETIME.TWO_MINUTE)){
          return;
        }
        thunkApi.dispatch(apiStore.actions.clearCache('playerexp'));
        const result = await Player.playerexp(payload.playerId, payload.type, payload.actionId);
        if(!result) {
           thunkApi.dispatch(apiStore.actions.setFailed('playerexp'));
          }else{
            thunkApi.dispatch(apiStore.actions.setSuccess({action: 'playerexp', payload: payload, data: result}));
          }
      } catch (e) {
          LogHelper.logError('Store/api/action/round', 'Loading playerexp failed: ', e);
          thunkApi.dispatch(apiStore.actions.setFailed('playerexp'));
      }
    }) */ 
}


/*
 * REDUCER
 */

const apiStore = createSlice({
  name: "api",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<string>) => {
      state.cache[action.payload].state = 1;
    },
    setSuccess: (state, action: PayloadAction<{action: string, payload:any,  data: any}>) => {
      state.cache[action.payload.action] = {state: 2, time: new Date().getTime(), payload: action.payload.payload, data: action.payload.data};
    },
    setFailed: (state, action: PayloadAction<string>) => {
      state.cache[action.payload] = {state: 3, time: 0, payload: undefined, data: undefined};
    },
    clearCache: (state, action: PayloadAction<string>) => {

      state.cache[action.payload] = {state: 0, time: 0, payload: undefined, data: undefined};
    },
    clearStore: (state, action: PayloadAction) => {
      state = {...initialState}
    }
    //sort: state => state.sort((a, b) => a.message.localeCompare(b.message))
  },
  extraReducers: (builder) => {
  }
  
});


export default apiStore;
