import { useAuthMethod } from "@crema/utility/AuthHooks";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fonts } from "shared/constants/AppEnums";
import { sessionStoreAction } from "store/session.store";
import { AppDispatch, RootState } from "store/store";


const AppWrapperHeader: React.FC<any> = () => {
  const storeDispatch: any = useDispatch<any>();

  const navigate = useNavigate();
  const {logout} = useAuthMethod();
  const storeAccount = useSelector((state: RootState) => state.session.account);


  const _logout = () => {
     logout();
  }

  return (
        <Grid container spacing={4}>
          <Grid item>
            <img src="/assets/images/big_logo.png"  alt={"Logo Kartln"} height="100%" style={{flex:1,maxWidth: 60}} /> 
          </Grid>
          <Grid item flexGrow={1}>
          <Box
              component="span"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 22,
                marginBottom: 10,
                color: '#ffffff'
              }}
            >
         #{storeAccount?.accountId} {storeAccount?.username}
          </Box>
          <br/>
          <Box
              component="span"
              sx={{
                fontWeight: Fonts.MEDIUM,
                fontSize: 16,
                color: '#ffffff'
              }}
            >
         {storeAccount?.accountEmail}<br/>
          </Box>
          </Grid>
          
          <Grid item>
              <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    minWidth: 160,
                    fontWeight: Fonts.REGULAR,
                    fontSize: 16,
                    textTransform: "capitalize",
                    padding: "4px 16px 8px",
                  }}
                  onClick={_logout}
                >
                  <IntlMessages id="kartln.general.logout" />
                </Button>
          </Grid>
        </Grid>);
        
};
export default AppWrapperHeader;
