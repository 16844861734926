import { authRouteConfig } from "./auth";
import { initialCustomerUrl, initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import { accountConfigs } from "./account";
import { publicConfigs } from "./public";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [ 
    ...accountConfigs
    ],
};

const unAuthorizedStructure = {
  fallbackPath:initialUrl,
  routes: [
    ...authRouteConfig,
    ...publicConfigs
  ],
};


const anonymousStructure = {
  routes: [
    ...errorPagesConfigs,
  ],
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
