import { Provider } from "react-redux";
import AppContextProvider from "@crema/utility/AppContextProvider";
import { sessionStoreAction } from "store/session.store";
import ApiClient from "services/api/ApiClient";
import { store } from "store/store";
import AppInit from "AppInit";


export const Api = new ApiClient();
//store.dispatch<any>(sessionStoreAction.initConnection());



const App = () => {

  return (<AppContextProvider>
    <Provider store={store}>
      <AppInit />
    </Provider>
  </AppContextProvider>)
};

export default App;
