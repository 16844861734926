
export default {
    api: {
        apiUrl: process.env.NODE_ENV === "production" ? 'https://kartlnapi.kube.kartln.eu' : process.env.NODE_ENV === "test" ? 'https://test-kartlnapi.kube.kartln.eu' : 'https://kartlnapidev.jeffsoft.de',
        apiKey1: process.env.ENVIRONMENT == 'production' ? 'sZ3ls2Up6cW'  : 'sZ3ls2Up6cW',
        apiKey2:  process.env.ENVIRONMENT == 'production' ? 'W3fGFus9saAsj' : 'W3fGFus9saAsj',
        timeout: 5000,
    },
    urls:{
        androidInvUrl: 'https://play.google.com/apps/testing/de.jeffsoft.kartln',
        iosInvUrl: 'https://testflight.apple.com/join/woBACD9I',
        termsUrl: 'https://app.kartln.eu/assets/docs/terms.txt',
        privacyUrl: 'https://app.kartln.eu/assets/docs/privacy.txt',
    },
    debug: 0,
    version: 'v1.3.0',
    buildTxt: '2023-11-22T21:59:10+01:00 - a0be9ef3',

};
